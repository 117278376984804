body,
html {
  padding: 0;
  margin: 0;
  width: 100%;
  overflow-x: hidden;
  font-family: "Roboto, sans-serif";
}

html[data-scroll="108"] .navbarTop {
  visibility: visible !important;
}

h2,
h3,
h4 {
  color: #122e5c;
  font-family: LudicrousStencil, Roboto, sans-serif;
  font-size: 2.8rem;
  font-weight: normal;
  margin-block-end: 0.01em;
}

h3 {
  font-size: 2.1rem;
}

h4 {
  color: #122e5c;
  font-size: 1.1rem;
}

p {
  font-family: Dosis, Roboto, sans-serif;
  text-align: justify;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: 0.00938em;
  font-size: 1.2rem;
  color: #122e5c;
}

li {
  font-family: Dosis, Roboto, sans-serif;
  font-size: 1.2rem;
  letter-spacing: 0.00938em;
  color: #122e5c;
}

.fade-in {
  transition: opacity 1s ease;
}
.fade-out {
  opacity: 0;
  transition: opacity 1s ease;
}
